"use client";

import Link from "next/link";
import { useLogin } from "../../../lib/api/useLogin";
import { Suspense, useEffect, useState } from "react";
import { Icon } from "../../../core/icon";
import { useSearchParams } from "next/navigation";
import Image from "next/image";
import { signInWithGoogle } from "@/src/lib/api/signInWithGoogle";
import { useDispatch, useSelector } from "react-redux";
import { resetRegisterState, setEmail, setPassword } from "@/src/redux/slices/register_slice";
import { RootState } from "@/src/redux/store";

const Login = () => {
  const { error, login, setUserData } = useLogin();
  const { email, password } = useSelector((state: RootState) => state.register);
  const dispatch = useDispatch();
  const credential = useSelector((state: RootState) => state.register.email);
  useEffect(() => {
    console.log("reseting register state", email);
    dispatch(resetRegisterState());
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const [clicked, setClicked] = useState({
    email: false,
    password: false,
  });
  const searchParams = useSearchParams();
  const affiliate: string = searchParams?.get("affiliate") || "";
  const referral: string = searchParams?.get("referral") || "";
  const source: string = searchParams?.get("source") || "";
  const callback: string = searchParams?.get("callback") || "";
  const paramsArray = [];
  if (affiliate !== "") {
    paramsArray.push(`affiliate=${affiliate}`);
  }

  if (referral !== "") {
    paramsArray.push(`referral=${referral}`);
  }

  if (source !== "") {
    paramsArray.push(`source=${source}`);
  }
  const params = paramsArray.length > 0 ? `?${paramsArray.join("&")}` : "";

  const handleLogin = async () => {
    dispatch(resetRegisterState());
    setClicked({ email: true, password: true });
    setIsLoading(true);
    await login(callback);
    setIsLoading(false);
  };

  const handleLoginWithGoogle = async () => {
    dispatch(resetRegisterState());
    try {
      const { user, token } = await signInWithGoogle();
      const email = user.email;
      if (email) {
        dispatch(setEmail(email));
      }
      console.log("Logged in user:", user);
      // router.push('/dashboard');
      await setUserData(user, callback);
      // You can redirect or store the user data in your state/context here
    } catch (error) {
      console.error("Failed to sign in with Google:", error);
    }
  };

  return (
    <div className="flex flex-col gap-y-[1em]">
      <Image
        src="/sellraze_blue_icon_rounded-xl.png"
        width="55"
        height="55"
        alt="Sellraze Logo"
        className="self-center"
      />
      <h1 className="[ text-32 fg-dark-100 ] font-sfpro-heavy font-bold tracking-[-0.6px]">Welcome back to SellRaze</h1>
      <p className="font-sfpro-regular self-center text-[14px] text-[#666666]">
        Don’t have an account?{" "}
        <Link
          className="font-sfpro-bold text-[14px] font-semibold text-sellRazeBlue"
          href={`/register${callback ? `?callback=${callback}` : ""}`}
        >
          Register for free.
        </Link>
      </p>
      <form className="flex flex-col gap-y-[1em]" onSubmit={handleLogin}>
        {/* <label className="[ fg-min-grey text-16]" htmlFor="email">
          Email address 
        </label> */}
        <input
          className={`rounded-2xl bg-gray-100 p-4 text-[#545454]`}
          type="email"
          id="text"
          name="email"
          placeholder="Email"
          required
          value={email}
          onChange={(evt) => dispatch(setEmail(evt.target.value))}
          onFocus={() => {
            setClicked((prev) => ({ ...prev, email: true }));
          }}
        />
        {/* <label className="[ fg-min-grey text-16]" htmlFor="password">
          Password
        </label> */}
        <input
          className={`rounded-2xl bg-gray-100 p-4 text-[#545454]`}
          type="password"
          id="password"
          name="password"
          placeholder="Password"
          required
          minLength={8}
          maxLength={1024}
          value={password}
          onChange={(evt) => dispatch(setPassword(evt.target.value))}
          onFocus={() => {
            setClicked((prev) => ({ ...prev, password: true }));
          }}
        />
        {error && <span className="[ fg-red ]">{error}</span>}
      </form>
      <button
        className="rounded-2xl bg-sellRazeBlue p-4 font-medium text-white items-center justify-center flex"
        data-variant="black"
        data-size="thin"
        onClick={handleLogin}
        disabled={isLoading}
      >
        {isLoading ? (
          <Icon className="[ spinner ] [ fg-white ]" icon="loading" width="20" height="20" alt="loading..." />
        ) : (
          "Log in"
        )}
      </button>
      <div className="flex items-center justify-center">
        <div className="h-[1px] w-1/2 bg-gray-300"></div>
        <span className="mx-4 font-semibold text-gray-400">OR</span>
        <div className="h-[1px] w-1/2 bg-gray-300"></div>
      </div>
      <button
        className="flex flex-row items-center justify-center rounded-2xl border p-4 font-medium text-black"
        data-variant="black"
        data-size="thin"
        onClick={handleLoginWithGoogle}
        disabled={isLoading}
      >
        <img
          src="/google_black.svg" // replace with your image source
          alt="Google"
          width="17"
          height="17"
          className="mr-2"
        />
        Log in with Google
      </button>
      <Link href={"/reset" + params} className="text[14px] self-center font-semibold text-sellRazeBlue">
        Forgot password?
      </Link>
    </div>
  );
};

const LoginWithSuspense = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Login />
    </Suspense>
  );
};

const LoginFallback = () => {
  return (
    <>
      <div className="[ login__column ] [ column ] gap-1">
        <h1 className="[ text-32 fg-dark-100 ]">Welcome Back</h1>
        <form className="[ column ] gap-1">
          <label className="[ fg-min-grey text-16]" htmlFor="email">
            Email Address
          </label>
          <input
            className="[ login__input ] [ input ] [ text-18 round ]"
            type="email"
            id="text"
            name="email"
            placeholder="Email Address"
            required
          />
          <label className="[ fg-min-grey text-16]" htmlFor="password">
            Password
          </label>
          <input
            className="[ login__input ] [ input ] [ text-18 round ]"
            type="password"
            id="password"
            name="password"
            placeholder="Password"
            required
            minLength={8}
            maxLength={1024}
          />
        </form>
        <Link href={"/"} className="[ fg-min-grey text-14 ] underline">
          Forgot Password?
        </Link>
        <button className="[ button ] [ width-fit ] [ text-18 round ]" data-variant="black" data-size="thin">
          ( &quot;Sign In&quot; )
        </button>
        <p className="[ fg-min-grey text-14 ]">
          Don’t have an account?{" "}
          <Link className="[ fg-true-blue ] underline" href={""}>
            Register for free.
          </Link>
        </p>
      </div>
      
    </>
  );
};

export default LoginWithSuspense;

// export default Login;
